// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import "application.scss"

import "vendor/bootstrap.bundle.min.js"; // Loads Bootstrap 5.3
import "vendor/purecounterjs/dist/purecounter_vanilla.js";

import flatpickr from "flatpickr";
// import "flatpickr/dist/flatpickr.min.css";
import { Choices } from "choices.js/public/assets/scripts/choices.min.js";
// import { tns } from "tiny-slider/dist/min/tiny-slider.js";
// import { tns } from "tiny-slider/src/tiny-slider.js";
import { tns } from 'tiny-slider/src/tiny-slider'
window.tns = tns

// require("choices.js/public/assets/styles/choices.min.css");
// import "choices.js/public/assets/styles/choices.min.css";
// import "flatpickr/dist/flatpickr.min.css";
// import "choices.js/public/assets/styles/choices.min.css";
// import "components/datepickers.js"
// import "components/slider.js"

import noUiSlider from 'nouislider';
// import "vendor/nouislider.min.js";
window.noUiSlider = noUiSlider

// import "../vendor/sticky.min.js";
import "components/rentals/show.js";
// import "components/contactus.js";
// import "components/cookies_bar.js";

import dayjs from 'dayjs'
import lazysizes from 'lazysizes'

$(document).on("turbo:load", function() {
  // new Blazy(
  //   {
  //     src: 'data-lazysrc',
  //     breakpoints: [
  //       {
  //         width: 420,
  //         src: 'data-lazysrcsm'
  //       }
  //     ],
  //     success: (element) => {
  //       setTimeout(() => {
  //         let parent = element.parentNode
  //         parent.className = parent.className.replace(/\bloading\b/, '')
  //       }, 300)
  //     }
  //   }
  // )

  $('.home-checkin').on('change', function (e) {
    let hm_checkin = $(e.target).val()
    let hm_checkout = $('.home-checkout').val()
    hm_checkin = new Date(hm_checkin)
    if(hm_checkout == '' || (hm_checkout != '' && hm_checkin >= new Date(hm_checkout))) {
      $('.home-checkout').val(dayjs(hm_checkin).add(1, 'days').format('YYYY-MM-DD'))
      $('.home-checkout').flatpickr('update', dayjs(hm_checkin).add(1, 'days').format('YYYY-MM-DD'))
      // $('.home-checkout').trigger('change')
    }
  })

  $('.home-checkout').on('change', function (e) {
    let checkout = $(e.target).val()
    let checkin = $('.home-checkin').val()
    if(new Date(checkout) <= new Date(checkin) || checkin == '') {
      $('.home-checkin').val(dayjs(checkout).subtract(1,'days').format('YYYY-MM-DD'))
      $('.home-checkin').flatpickr('update', dayjs(checkout).subtract(1,'days').format('YYYY-MM-DD'))
    }
  })


})

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
